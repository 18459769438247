import React from "react";
import Layout from "../../components/Layout";

export default () => (
  <Layout>
    <section className="section">
      <div className="container">
        <div className="content">
          <h1>Thank you!</h1>
          <p>
            We'll usually be in touch within 12 hours. If you don't hear back
            from us, please check your email spam folder, email us at{" "}
            <a href="mailto:info@rwakoborock.com">info@rwakoborock.com</a> or
            call us on <a href="tel:+256 755 211771">+256 755 211771</a>.
          </p>
        </div>
      </div>
    </section>
  </Layout>
);
